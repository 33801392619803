import React, { useState } from 'react'
import './assets/styles/_index.scss'
import { Container, Modal } from 'react-bootstrap';
import loadable from '@loadable/component';
import { navigate } from 'gatsby';

const TeamDetail = loadable(() => import("../TeamDetail/TeamDetail"));
const { ImageModule } = require("@starberry/gatsby-theme-utils/Modules/ImageModule")


const TeamCard = (props) => {

    const [modalShow, setModalShow] = useState(false);
    const [item_id, setItemId] = useState("");

    const teamList = props.team;
    const ImageRenderList = ({ item, imagename }) => {
        let processedImages = JSON.stringify({});
        if (item?.imagetransforms?.image_Transforms) {
            processedImages = item?.imagetransforms?.image_Transforms;
        }
        return (
            <ImageModule ImageSrc={item.image} title={item.name} altText={`${item.name} ${item.designation ? ' | ' + item.designation : ''}`} imagetransforms={processedImages} renderer="srcSet" imagename={imagename} strapi_id={item.strapi_id} />
        )
    }

    return (
        <React.Fragment>
            <div className="team-card-wrapper">
                <div className="team-card-img-zoom img-wrapper">
                    <div className='bio-img' onClick={() => { setModalShow(true); setItemId(teamList.strapi_id) }}>
                        <ImageRenderList item={props?.team} imagename={"team.image.tile_image"} />
                    </div>
                </div>
                <div className="team-card-text-wrapper">
                    <div className="team-name" onClick={() => { setModalShow(true); setItemId(teamList.strapi_id) }}>{props.team.name}</div>
                    <div className="team-position">{props.team.designation}</div>
                    <ul className="list-inline team-card-list">
                        {props.team.phone &&
                            <>
                                <li className="list-inline-item">
                                    <a href={`tel:${props.team.phone}`} className="team-phone primary-text">{props.team.phone}</a>
                                </li>
                                <span className='stright-divider'></span>
                            </>
                        }
                        <li className="list-inline-item">
                            <a onClick={() => navigate('/about/our-team/contact/', { state: { name: props?.team?.name, email: props?.team?.email } })} className="team-email primary-text">Email</a>
                        </li>
                        <span className='stright-divider'></span>
                        <li className="list-inline-item">
                            <div onClick={() => { setModalShow(true); setItemId(teamList.strapi_id) }} className="team-email primary-text bio">Bio</div>
                        </li>
                    </ul>
                </div>
            </div>
            {item_id === teamList.strapi_id &&
                <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    className="team_modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Container>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <TeamDetail {...teamList} />
                        </Modal.Body>
                    </Container>
                </Modal>
            }
        </React.Fragment>
    )
}

export default TeamCard