import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import loadable from "@loadable/component";
import ScrollAnimation from 'react-animate-on-scroll';
import _ from "lodash";
import TeamsCard from "../TeamCard/TeamCard";
import TeamFilters from "../../filter_config/team.json";
import InnerPagination from "../SearchResultsPagination/SearchResultsPagination"
import TeamResultSection from "../TeamCard/TeamResultSection"
import { customStylesNews } from "../Common/utils";
const { usePagination } = require("@starberry/gatsby-theme-utils/Hooks/UsePagination")
const Select = loadable(() => import("react-select"));

const TeamsTab = (props) => {

    const [filter, setFilter] = useState(TeamFilters[0].value);
    const [officeVal, setOfficeVal] = useState('all');
    let [projects, setProjects] = useState(props.data);
    let [filteredList, setFilteredList] = useState(props.data);
    let [filterOptions, setFilterOptions] = useState('');
    let [officefilterOptions, setOfficeFilterOptions] = useState('');
    const selectOptions = [{ value: TeamFilters[0].value, label: TeamFilters[0].label }];

    const newClass = (event) => {
        var parent = document.getElementById('filter-link');
        parent.classList.add('filter-active');
        var allChildElements = parent.querySelectorAll('.nav-link');
        for (const box of allChildElements) {
            box.classList.remove('active');
        }
        event.target.classList.add('active')
    }

    const applyCategory = (filt) => {
        setProjects([]);

        if (document.getElementsByName('department')[0].value !== 'all' && document.getElementsByName('branch')[0].value !== 'all') {
            const filtered = _.filter(props.data, function (o) { return (o.node.category.strapi_json_value).includes(document.getElementsByName('department')[0].value); });
            const branchfiltered = _.filter(filtered, function (o) {
                let main_category = o.node.offices.filter(cat => _.includes(cat, document.getElementsByName('branch')[0].value));
                return main_category.length > 0
            });
            setProjects(branchfiltered);
            setFilteredList(branchfiltered);
        } else if (document.getElementsByName('department')[0].value !== 'all') {
            const filtered = _.filter(props.data, function (o) { return (o.node.category.strapi_json_value).includes(document.getElementsByName('department')[0].value); });
            setProjects(filtered);
            setFilteredList(filtered);
        } else if(document.getElementsByName('branch')[0].value !== 'all') {
            const branchfiltered = _.filter(props.data, function (o) {
                let main_category = o.node.offices.filter(cat => _.includes(cat, document.getElementsByName('branch')[0].value));
                return main_category.length > 0
            });
            setProjects(branchfiltered);
            setFilteredList(branchfiltered);
        }       
        else {
            setProjects(props.data);
            setFilteredList(props.data);
        }
    }


    // for pagination
    const itemsPerPage = 12
    const { currentItems, currentPage, setCurrentPage } = usePagination({
        items: filteredList,
        itemsPerPage,
    })
    // for pagination

    useEffect(() => {

        let officeList = [{ value: 'all', label: "All" }]

        _.forEach(props.data, ({ node }) => {
            if (node.offices && node.offices?.length > 0) {
                _.forEach(node.offices, (data) => {
                    officeList.push({ value: data.slug, label: data.title })
                })
            }
        })
        setOfficeFilterOptions(_.uniqBy(officeList, (e) => e.value))

        TeamFilters.slice(1, 100).map((item, i) => {
            _.filter(props.data, function (o) {
                if (o.node.category?.strapi_json_value && o.node.category?.strapi_json_value.length > 0 && ((o.node.category?.strapi_json_value).includes(item.value))) {
                    selectOptions.push({ value: item.value, label: item.label })
                }
            })
        })
        setFilterOptions(_.uniqBy(selectOptions, (e) => e.value))
    }, [filteredList])

    return (
        <>
            <TeamResultSection totalPeople={props.data.length} setOfficeVal={setOfficeVal} selectOptions={filterOptions} placeholder={TeamFilters[0].label} setFilter={setFilter} applyCategory={applyCategory} officefilterOptions={officefilterOptions} />
            {currentItems.length > 0 ?
                <Row>
                    <Col>
                        <div className="team-listing-wrapper">
                            {currentItems?.map(({ node }, index) => {
                                return (
                                    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={10}>
                                    <TeamsCard team={node} />
                                    </ScrollAnimation>
                                )
                            }
                            )}

                        </div>
                        <InnerPagination
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            totalItems={filteredList.length}
                            setCurrentPage={setCurrentPage}
                        />
                    </Col>
                </Row> :
                <p>Sorry, noresults found, please check after sometimes</p>}
        </>
    )
}

export default TeamsTab