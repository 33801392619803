import React from 'react'
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import loadable from '@loadable/component';
import LayoutTwo from '../components/layoutTwo';
import { Container } from 'react-bootstrap';

import TeamLanding from '../components/TeamLanding/TeamLanding';
const StaticIntro = loadable(() => import("../components/StaticIntro/StaticIntro"));
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));

const Teams = ({ data }, props) => {
  const PageData = data?.strapiPage
  const team = data?.allStrapiTeam.edges
  return (
    <LayoutTwo popularSearch={PageData?.select_popular_search?.title}>
      <div className="layout-padding-top"></div>
      <BreadcrumbModule subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title} subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug} parentlabel={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title} parentlink={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug} title={PageData.title}
      />
      {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
        return (
          <>
            {module.strapi_component === "page-modules.plain-content" && index === 0 &&
              <StaticIntro {...module} pagename={PageData.title} />}
            {module.strapi_component === "page-modules.global-module" && module.select_module === "team" && <Container><TeamLanding data={team} />
            </Container>}
          </>
        )
      })}



    </LayoutTwo>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
    <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
  )
}

export default Teams

export const query = graphql`
  query ($page_id: String) {
      strapiPage(id: {eq: $page_id}) {
          ...PageFragment
          Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
              ...GlobalModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
              ...ImageAndContentFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
              ...PlainContentFragment
            }
          }
      }
      allStrapiTeam(filter: {publish: {eq: true}}) {
          edges {
            node {
              ...TeamFragment
              slug
              offices {
                slug
                title
              }
              category {
                strapi_json_value
              }
            }
          }
      }
    }
  `