import React from 'react'
import loadable from '@loadable/component';
import { Col, Row } from 'react-bootstrap'
import { filterCustomStyles } from '../DropdownSelectSettings/DropdownSelectSettings';

const Select = loadable(() => import("react-select"));

const TeamResultSection = (props) => {

    return (
        <div className='team-result-section-wrapper'>
            <Row>
                <Col md={5} className="d-flex align-items-center">
                    <div className="team-result-count">Showing {props.totalPeople} people</div>
                </Col>
                <Col md={7}>
                    <ul className="list-inline team-sort-section d-flex justify-content-end align-items-center">
                        <li className="list-inline-item">
                            <div className="dropdown-select d-flex align-items-center">
                                <span>Departments :</span>
                                <Select
                                    options={props.selectOptions}
                                    isSearchable={false}
                                    onChange={(e) => {
                                        setTimeout(() => {
                                            props.setFilter(e.value);
                                            props.applyCategory(e.value);
                                        }, 100);
                                    }}
                                    name="department"
                                    // defaultValue={sortby_options[sortby_key]}
                                    value={props.selectOptions.value}
                                    placeholder={props.placeholder}
                                    // onChange={changesortby}
                                    className={"select-control"}
                                    classNamePrefix={"react-select"}
                                    styles={filterCustomStyles}
                                    components={{ DropdownIndicator: () => <i className="icon icon-select-dropdown-primary"></i>, IndicatorSeparator: () => null }}
                                />
                            </div>
                        </li>

                        <li className="list-inline-item">
                            <div className="stright-divider d-xl-block d-none"></div>
                        </li>
                        {props.officefilterOptions && props.officefilterOptions.length > 0 &&
                            <div className="dropdown-select d-flex align-items-center">
                                <Select
                                    options={props.officefilterOptions}
                                    isSearchable={false}
                                    onChange={(e) => {
                                        setTimeout(() => {
                                            props.setOfficeVal(e.value);
                                            props.applyCategory(e.value);
                                        }, 100);
                                    }}
                                    name="branch"
                                    // defaultValue={sortby_options[sortby_key]}
                                    value={props.officefilterOptions.value}
                                    placeholder={"Branch"}
                                    // onChange={changesortby}
                                    className={"select-control"}
                                    classNamePrefix={"react-select"}
                                    styles={filterCustomStyles}
                                    components={{ DropdownIndicator: () => <i className="icon icon-select-dropdown-primary"></i>, IndicatorSeparator: () => null }}
                                />
                            </div>
                        }
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default TeamResultSection